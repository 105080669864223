<template>
  <div class="auth">
    <div class="auth__img">
      <img src="../../assets/img/bg.svg" alt="background"/>
    </div>
    <div class="auth-form">
      <div class="cards__single">
        <h1 class="auth-form__title">{{ $vuetify.lang.t('$vuetify.recoveryPassword') }}</h1>
        <div class="auth-form__content">
          <v-form v-if="!uid" ref="auth" lazy-validation @submit.prevent="">
            <v-text-field
              v-model="user.login"
              label="E-mail"
              type="email"
              outlined
              :rules="[$rules.required, $rules.email, $rules.notSpaces]"
            ></v-text-field>
          </v-form>
          <v-form v-if="uid" ref="form" lazy-validation @submit.prevent="">
            <v-text-field
              v-model="password.password"
              :label="$vuetify.lang.t('$vuetify.password')"
              outlined
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[$rules.required, $rules.min, $rules.notSpaces]"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
              v-model="user.re_password"
              :label="$vuetify.lang.t('$vuetify.repeatPassword')"
              outlined
              :rules="[$rules.required, $rules.min, $rules.notSpaces, rules.same_password]"
              type="password"
            ></v-text-field>
          </v-form>
          <div v-if="showText" class="sign-up__subtitle">{{ $vuetify.lang.t('$vuetify.emailNotification') }}</div>
          <div v-if="alertError" class="error--text">
            {{ alertMsg }}
          </div>
          <button v-if="!uid" class="btn btn-primary" @click="resetPassword">
            {{ $vuetify.lang.t('$vuetify.recoveryButton') }}
          </button>
          <button v-if="uid" class="btn btn-primary" @click="confirmPassword">{{
              $vuetify.lang.t('$vuetify.confirm')
            }}
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordLayout",
  props: {
    uid: [undefined, String],
    token: [undefined, String],
  },

  data: function () {
    return {
      user: {
        login: "",
      },
      password: {
        password: "",
        re_password: "",
      },
      showText: false,
      show1: false,
      rules: {
        same_password: (v) => v === this.password.password || this.$vuetify.lang.t('$vuetify.samePasswordRule'),
      },
      alertError: false,
      alertMsg: "",
    };
  },

  created() {
    if (localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token")) {
      this.$router.push({name: "main"});
    }
  },

  methods: {
    resetPassword() {
      if (this.$refs.auth.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/auth/users/reset_password/",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.user.login.toLowerCase().replace(" ", ""),
          },
        }).then(() => {
        }).catch((error) => {
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
    confirmPassword() {
      if (this.$refs.form.validate()) {
        this.$axios({
          url: this.$hostname + "time-tracking/auth/users/reset_password_confirm/",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            uid: this.uid,
            token: this.token,
            new_password: this.password.password,
            re_new_password: this.password.re_password
          },
        }).then(() => {
          this.$router.push({name: "AuthLayout"})
        }).catch((error) => {
          let msgs = this.$catch(error)
          msgs.forEach(msg => {
            this.alertMsg += " " + msg.detail
          })
          this.alertError = true;
        });
      }
    },
  }
}
</script>

<style scoped>

</style>