<template>
  <div class="vacancy-view">
    <div class="vacancy-view__header">
      <div class="vacancy-view__title">{{ vacancy.name }}</div>
      <div class="vacancy-view__subtitle">{{ vacancy.position }}</div>

    </div>
    <div class="vacancy-view__main">
      <div class="vacancy-view__tabs">
        <div class="vacancy-view__tab" :class="{'active': tab==='details'}" @click="tab='details'">
          {{ $vuetify.lang.t('$vuetify.hh.details') }}
        </div>
        <div class="vacancy-view__tab" :class="{'active': tab==='highlights'}" @click="tab='highlights'">
          {{ $vuetify.lang.t('$vuetify.hh.highlights') }}
        </div>
        <div class="vacancy-view__tab" :class="{'active': tab==='company'}" @click="tab='company'">
          {{ $vuetify.lang.t('$vuetify.hh.company') }}
        </div>
      </div>
      <div class="vacancy-view__content">
        <div v-if="tab==='details'" class="vacancy-view__content-item">
          <div class="vacancy-view__content-block" v-if="vacancy.type">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.type') }}</div>
            <div class="vacancy-view__content-text">{{ $vuetify.lang.t("$vuetify.hh." + vacancy.type) }}</div>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.description">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.description') }}</div>
            <div class="vacancy-view__content-text" v-html="vacancy.description"></div>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.responsibilities">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.responsibilities') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.responsibilities }}</pre>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.skills">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.skills') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.skills }}</pre>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.documents">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.documents') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.documents }}</pre>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.useful">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.useful') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.useful }}</pre>
          </div>
        </div>
        <div v-if="tab==='highlights'" class="vacancy-view__content-item">
          <div class="vacancy-view__content-block">
            <div class="vacancy-view__salary">
              {{ $vuetify.lang.t("$vuetify.hh.salary") }}:
              <template v-if="vacancy.salary_min"> {{ vacancy.salary_min }}</template>
              <template v-if="vacancy.salary_max"> - {{ vacancy.salary_max }}</template>
              <template v-if="vacancy.currency"> {{ vacancy.currency }}</template>
              <template v-if="vacancy.salary_type"> {{ vacancy.salary_type }}</template>
              <template v-if="vacancy.salary_min"> {{ vacancy.netto ? $vuetify.lang.t("$vuetify.hh.brutto") : $vuetify.lang.t("$vuetify.hh.netto") }}</template>
            </div>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.need_experience">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.need_experinece') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.experience }}</pre>
          </div>
          <div class="vacancy-view__content-block" v-if="vacancy.conditions">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.conditions') }}</div>
            <pre class="vacancy-view__content-text">{{ vacancy.conditions }}</pre>
          </div>

          <div class="vacancy-view__content-block" v-if="vacancy.address">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.location') }}</div>
            <div class="vacancy-view__content-text">
              {{ vacancy.address }}
            </div>
          </div>
          <div class="vacancy-view__content-block">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.remote') }}</div>
            <div class="vacancy-view__content-text">
              {{ vacancy.remote ? $vuetify.lang.t('$vuetify.yes') : $vuetify.lang.t('$vuetify.no') }}
            </div>
          </div>
          <div class="vacancy-view__content-block">
            <div class="vacancy-view__content-title">{{ $vuetify.lang.t('$vuetify.hh.contact_person') }}</div>
            <div class="vacancy-view__contact">
              <div class="vacancy-view__contact-left">
                <div class="vacancy-view__contact-name">
                  {{ vacancy.contact_firstname }} {{ vacancy.contact_lastname }}
                </div>
                <a v-if="vacancy.contact_phone" :href="'tel:' + contact_phone" class="vacancy-view__contact-phone">
                  {{ vacancy.contact_phone }}
                </a>
                <a v-if="vacancy.contact_email" :href="'mailto:' + contact_email" class="vacancy-view__contact-email">
                  {{ vacancy.contact_email }}
                </a>
              </div>
              <div class="vacancy-view__contact-right" v-if="vacancy.contact_photo">
                <img :src="vacancy.contact_photo" class="vacancy-view__contact-photo" alt="contact">
              </div>

            </div>
          </div>
        </div>
        <div v-if="tab==='company'" class="vacancy-view__content-item">
          <div class="vacancy-view__content-block">
            <router-link :to="{name: 'CompanyProfile', params: {id: vacancy.organization.id}}" class="flex">
              <div class="profile-main__avatar">
                <img v-if="vacancy.organization.logo_path" :src="$media_url + vacancy.organization.logo_path" alt=""/>
              </div>

              <div class="profile-main__name h2">
                {{ vacancy.organization.name }}
              </div>
            </router-link>
          </div>
          <div v-if="vacancy.organization.description" class="vacancy-view__content-block">
            <div v-html="vacancy.organization.description"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="vacancy-view__right">
      <button class="btn btn-primary" @click="makeOfferToCompanyModal = true">
        {{ $vuetify.lang.t('$vuetify.hh.respond') }}
      </button>
    </div>


    <MakeOfferToCompanyModal v-model="makeOfferToCompanyModal" :vacancy_id="vacancy.id"></MakeOfferToCompanyModal>
  </div>
</template>

<script>
import MakeOfferToCompanyModal from "@/components/MakeOfferToCompanyModal.vue";

export default {
  name: "VacancyView",

  components: {
    MakeOfferToCompanyModal,
  },

  data: function () {
    return {
      makeOfferToCompanyModal: false,

      vacancy: {},

      tab: 'details'

    }
  },

  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/vacancies/" + this.$route.params.id,
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.vacancy = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

  }
}
</script>
