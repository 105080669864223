<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1 class="h1 dTm-none">{{ $vuetify.lang.t('$vuetify.menu.CVs') }}</h1>
    </div>
    <div class="page-head page__sorts">
      <v-text-field class="search-field" v-model="search" @keypress.enter="page=1; loadData()"
                    @keypress.tab="page=1; loadData()" enterkeyhint="search"
                    :placeholder="$vuetify.lang.t('$vuetify.sorts.search')" outlined
                    prepend-inner-icon="$search" hide-details>
      </v-text-field>
      <v-select class="sorts__select show" v-model="status"
                :label="$vuetify.lang.t('$vuetify.cvs.status')"
                :items="statuses" item-text="name" item-value="value"
                :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                      maxHeight: 200,
                                      contentClass: 'select-menu select-menu--sort',
                                  }" outlined hide-details attach>

        <template v-slot:selection="{item }">
          {{ $vuetify.lang.t("$vuetify.cvs.statuses." + item) }}
        </template>
        <template v-slot:item="{ item }">
          {{ $vuetify.lang.t("$vuetify.cvs.statuses." + item) }}
        </template>
      </v-select>

      <address-autocomplete :label="$vuetify.lang.t('$vuetify.addressVacancy')"
                            @input="inputCoordinates" @clear="clearCoordinates"></address-autocomplete>
    </div>

    <div class="cvs-list">
      <div class="cvs-item" v-for="cv in cvs" :key="cv.id">
        <div class="cvs-item__photo">
          <img :src="$media_url + (cv.photo_path ? cv.photo_path : 'users/noavatar.png')" alt="">
        </div>

        <div class="cvs-item__title">
          {{ cv.firstname }} {{ cv.lastname }} <span v-if="cv.position">- {{ cv.position }}</span>
        </div>
        <div class="cvs-item__text">

          <div class="cvs-item__skills">
            {{ cv.skills }}
          </div>
          <div class="cvs-item__region">
            {{ cv.residence_address }}
          </div>
          <div class="cvs-item__status" :class="'cvs-item__status--'+cv.auth_user.userprofile.status">
            {{ $vuetify.lang.t('$vuetify.cvs.statuses.' + cv.auth_user.userprofile.status) }}
          </div>
        </div>
        <div class="cvs-item__btns">
          <a :href="'/cvs/'+cv.id" class="btn btn-primary">
            {{ $vuetify.lang.t('$vuetify.open') }}
          </a>
          <button class="btn btn-secondary" @click="openRespond(cv)">
            {{ $vuetify.lang.t('$vuetify.cvs.invite') }}
          </button>
        </div>
      </div>
    </div>

    <MakeOfferToWorkerModal :value="makeOfferModal" :cv_id="cv_id"></MakeOfferToWorkerModal>

    <spinner-loader v-model="loader"></spinner-loader>
  </div>
</template>

<script>
import MakeOfferToWorkerModal from "@/components/MakeOfferToWorkerModal.vue";
import {mapActions} from "vuex";
import AddressAutocomplete from "@/components/AddressAutocomplete.vue";
import SpinnerLoader from "@/components/SpinnerLoader.vue";

export default {
  name: "CVsView",
  components: {SpinnerLoader, AddressAutocomplete, MakeOfferToWorkerModal},

  data: function () {
    return {
      loader: false,

      cvs: [],

      search: '',
      status: '',
      statuses: [
        'busy',
        'free',
        'busy_additional',
      ],
      latitude: null,
      longitude: null,

      makeOfferModal: false,
      cv_id: null,
    }
  },

  mounted() {
    this.checkPermission('HR').then(() => {
      this.loadData()
    })
  },

  methods: {
    ...mapActions(['checkPermission']),
    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/resume",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          sort: this.sort,
          search: this.search,
          status: this.status,
          page: this.page,
          per: this.perPage,
          lat: this.latitude,
          lng: this.longitude
        }
      }).then((response) => {
        this.objects = [];
        if (response.data.data.data) {
          this.length = response.data.data.pages
          this.cvs = response.data.data.data;
        }
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },
    openRespond(cv) {
      this.makeOfferModal = true
      this.cv_id = cv.id
    },
    inputCoordinates(lat, lng) {
      this.latitude = lat
      this.longitude = lng
      this.loadData()
    },
    clearCoordinates() {
      this.latitude = null
      this.longitude = null
      this.loadData()
    }
  }
}
</script>