import { render, staticRenderFns } from "./FindOrganizationsList.vue?vue&type=template&id=2fcdb4a4&scoped=true&"
import script from "./FindOrganizationsList.vue?vue&type=script&lang=js&"
export * from "./FindOrganizationsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fcdb4a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VPagination,VTextField})
