<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.Responds') }}</h1>
    </div>

    <div class="responds-lists">
      <table class="main-table">
        <thead>
        <td>
          {{ $vuetify.lang.t('$vuetify.date') }}
        </td>
        <td v-if="side === 'worker'">
          {{ $vuetify.lang.t('$vuetify.hh.company') }}
        </td>
        <td v-if="side === 'organization'">
          {{ $vuetify.lang.t('$vuetify.hh.worker') }}
        </td>
        <td>
          {{ $vuetify.lang.t('$vuetify.hh.vacancy') }}
        </td>
        <td>
          {{ $vuetify.lang.t('$vuetify.hh.CV') }}
        </td>
        <td>
        </td>
        </thead>
        <tbody>
        <tr v-for="item in responds" :key="item.id">
          <td>{{ new Date(item.create_date).toLocaleDateString('ru-Ru') }}</td>
          <td v-if="side === 'worker'">
            <router-link :to="{name: 'CompanyProfile', params: {id: item.organization.id}}">
              {{ item.organization.name }}
            </router-link>
          </td>
          <td v-if="side === 'organization'">
            <router-link :to="{name: 'workers-item', params: {id: item.auth_user.userprofile.id}}">
              {{ item.auth_user.userprofile.name }} {{ item.auth_user.userprofile.lastname }}
            </router-link>
          </td>
          <td>
            <router-link v-if="item.vacancy" :to="{name: 'VacancyView', params: {id: item.vacancy.id}}">
              {{ item.vacancy ? item.vacancy.name : $vuetify.lang.t('$vuetify.no') }}
            </router-link>
            <span v-else>{{ $vuetify.lang.t('$vuetify.no') }}</span>
          </td>
          <td>
            <router-link v-if="item.resume" :to="{name: 'CV', params: {id: item.resume.id}}">
              {{ item.resume ? item.resume.name : $vuetify.lang.t('$vuetify.no') }}
            </router-link>
            <span v-else>{{ $vuetify.lang.t('$vuetify.no') }}</span>
          </td>
          <td>
            <router-link :to="{name: 'Respond', params: {id: item.id}}" class="my-3 btn btn-primary link-not-like">
              {{ $vuetify.lang.t('$vuetify.open') }}
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import VacancyView from "@/views/open/VacancyView.vue";
import CVView from "@/views/open/CVView.vue";
import {mapActions} from "vuex";

export default {
  name: "RespondsView",
  computed: {
    CVView() {
      return CVView
    },
    VacancyView() {
      return VacancyView
    }
  },

  data: function () {
    return {
      side: '',
      url: '',

      responds: {},

      alertMsg: "",
      alertError: false,
      loader: false,
    }
  },

  mounted() {
    if (this.$route.path.indexOf('organization') !== -1) {
      this.checkPermission('HR').then(() => {
        this.side = 'organization'
      })
      this.url = 'time-tracking/vacancy-resume'
    } else {
      this.side = 'worker'
      this.url = 'time-tracking/resume-vacancies'
    }
    this.loadData()
  },

  methods: {
    ...mapActions(['checkPermission']),
    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + this.url,
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.responds = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },
  }
}
</script>

