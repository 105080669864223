var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-head"},[_c('h1',{staticClass:"h1 dTm-none"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.menu.CVs')))])]),_c('div',{staticClass:"page-head page__sorts"},[_c('v-text-field',{staticClass:"search-field",attrs:{"enterkeyhint":"search","placeholder":_vm.$vuetify.lang.t('$vuetify.sorts.search'),"outlined":"","prepend-inner-icon":"$search","hide-details":""},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page=1; _vm.loadData()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.page=1; _vm.loadData()}]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"sorts__select show",attrs:{"label":_vm.$vuetify.lang.t('$vuetify.cvs.status'),"items":_vm.statuses,"item-text":"name","item-value":"value","menu-props":{
                                    bottom: true,
                                    offsetY: true,
                                    maxHeight: 200,
                                    contentClass: 'select-menu select-menu--sort',
                                },"outlined":"","hide-details":"","attach":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cvs.statuses." + item))+" ")]}},{key:"item",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cvs.statuses." + item))+" ")]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('address-autocomplete',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.addressVacancy')},on:{"input":_vm.inputCoordinates,"clear":_vm.clearCoordinates}})],1),_c('div',{staticClass:"cvs-list"},_vm._l((_vm.cvs),function(cv){return _c('div',{key:cv.id,staticClass:"cvs-item"},[_c('div',{staticClass:"cvs-item__photo"},[_c('img',{attrs:{"src":_vm.$media_url + (cv.photo_path ? cv.photo_path : 'users/noavatar.png'),"alt":""}})]),_c('div',{staticClass:"cvs-item__title"},[_vm._v(" "+_vm._s(cv.firstname)+" "+_vm._s(cv.lastname)+" "),(cv.position)?_c('span',[_vm._v("- "+_vm._s(cv.position))]):_vm._e()]),_c('div',{staticClass:"cvs-item__text"},[_c('div',{staticClass:"cvs-item__skills"},[_vm._v(" "+_vm._s(cv.skills)+" ")]),_c('div',{staticClass:"cvs-item__region"},[_vm._v(" "+_vm._s(cv.residence_address)+" ")]),_c('div',{staticClass:"cvs-item__status",class:'cvs-item__status--'+cv.auth_user.userprofile.status},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cvs.statuses.' + cv.auth_user.userprofile.status))+" ")])]),_c('div',{staticClass:"cvs-item__btns"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":'/cvs/'+cv.id}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.open'))+" ")]),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.openRespond(cv)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cvs.invite'))+" ")])])])}),0),_c('MakeOfferToWorkerModal',{attrs:{"value":_vm.makeOfferModal,"cv_id":_vm.cv_id}}),_c('spinner-loader',{model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }