<template>
  <div class="page-wrap">
    <div class="page-head">
      <h1>{{ $vuetify.lang.t('$vuetify.menu.Vacancies') }}</h1>

    </div>

    <div class="page-head page-head--sorts">
      <div class="page__sorts">
        <v-text-field class="search-field" v-model="search" @keypress.enter="page=1; loadData()"
                      @keypress.tab="page=1; loadData()" enterkeyhint="search"
                      :placeholder="$vuetify.lang.t('$vuetify.sorts.search')" outlined
                      prepend-inner-icon="$search" hide-details>
        </v-text-field>

        <address-autocomplete :label="$vuetify.lang.t('$vuetify.address')"
                              @input="inputCoordinates" @clear="clearCoordinates"></address-autocomplete>
        <v-text-field v-model.number="radius" type="number" :label="$vuetify.lang.t('$vuetify.cvs.radius')"
                      outlined hide-details></v-text-field>
        <button class="btn-action btn-icon" @click="loadData">
          <search-icon></search-icon>
        </button>
      </div>
    </div>

    <vacancy-list :vacancies="vacancies" :show-edit="false" @openRespond="openRespond"></vacancy-list>


    <!--    <div class="vacancies-list">-->
    <!--      <div class="vacancy-item" v-for="vacancy in vacancies" :key="vacancy.id">-->
    <!--        <a :href="'/vacancies/'+vacancy.id" class="vacancy-item__name">{{ vacancy.name }}</a>-->
    <!--        <div class="vacancy-item__sub-line">-->
    <!--        <div class="vacancy-item__salary">{{ vacancy.salary }}</div>-->
    <!--        <div v-if="vacancy.remote" class="vacancy-item__remote">{{$vuetify.lang.t('$vuetify.hh.remote')}}</div>-->
    <!--        </div>-->
    <!--        <div class="vacancy-item__company">-->
    <!--          ООО компания-->
    <!--        </div>-->
    <!--        <div class="vacancy-item__address">-->
    <!--          {{vacancy.country}}, {{ vacancy.region }} {{ vacancy.city }} {{ vacancy.address}}-->
    <!--        </div>-->

    <!--        <div class="vacancy-item__buttons">-->
    <!--          <button class="btn btn-primary">{{$vuetify.lang.t('$vuetify.hh.respond')}}</button>-->
    <!--          <button class="btn btn-primary" @click="openEdit(vacancy)">{{$vuetify.lang.t('$vuetify.edit')}}</button>-->
    <!--          <button class="btn btn-secondary" @click="confirmDelete(vacancy)">{{$vuetify.lang.t('$vuetify.delete')}}</button>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--    </div>-->

    <MakeOfferToCompanyModal v-model="makeOfferModal" :vacancy_id="offer_vacancy_id"></MakeOfferToCompanyModal>

  </div>
</template>

<script>
import VacancyList from "@/components/VacancyList.vue";
import MakeOfferToCompanyModal from "@/components/MakeOfferToCompanyModal.vue";
import AddressAutocomplete from "@/components/AddressAutocomplete.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";

export default {
  name: "my-vacancies",
  components: {SearchIcon, AddressAutocomplete, VacancyList, MakeOfferToCompanyModal},

  data: function () {
    return {
      loader: false,
      form_loader: false,
      alertMsg: "",
      alertError: false,

      search: '',
      vacancies: [],

      radius: null,
      latitude: null,
      longitude: null,

      offer_vacancy_id: null,
      makeOfferModal: false
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    openRespond(vacancy) {
      this.offer_vacancy_id = vacancy.id
      this.makeOfferModal = true
    },

    loadData() {
      this.loader = true
      this.$axios({
        url: this.$hostname + "time-tracking/vacancies",
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
        params: {
          search: this.search,
          page: this.page,
          per: this.perPage,
          lat: this.latitude,
          lng: this.longitude,
          radius: this.radius,
        }
      }).then((response) => {
        this.vacancies = response.data.data.data
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

    inputCoordinates(lat, lng) {
      this.latitude = lat
      this.longitude = lng
    },
    clearCoordinates() {
      this.latitude = null
      this.longitude = null
    },

  }
}
</script>