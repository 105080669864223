<template>

  <div class="container">
    <div class="page">
      <h1> Account Deletion Information </h1>
      <p><strong>Work24.fi</strong></p>
      <section>
        <h2>How to Delete Your Account</h2>
        <p>We're sorry to see you go. If you wish to delete your account, please be aware that self-deletion of accounts is not currently possible through our website.</p>
        <p>To request the deletion of your account, please send an email to <a href="mailto:noreply.work24.fi@gmail.com">noreply.work24.fi@gmail.com</a> with the subject line "Account Deletion Request" and include your account details in the body of the email.</p>
        <p>We will review your request and respond within 10 days. If your request is approved, your account will be permanently deleted, and you will receive a confirmation email once the process is complete.</p>
        <p>Thank you for using Work24.fi. If you have any questions or need further assistance, feel free to contact our support team.</p>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: "DeleteAccountLayout",
}
</script>

<style scoped>

</style>