<template>
  <div class="comments__item" :key="com.id">
    <div class="comments__avatar avatar" v-if="com.auth_user">
      <img v-if="com.auth_user.userprofile && com.auth_user.userprofile.photo_path"
           :src="$media_url + com.auth_user.userprofile.photo_path"
           alt=""/>
    </div>
    <div class="comments__name" v-if="com.auth_user && com.auth_user.id">
      <router-link :to="'/workers/' + com.auth_user.userprofile.id"
                   class="link-not-like">
        {{ com.auth_user.userprofile.name }}
        {{ com.auth_user.userprofile.lastname }}
      </router-link>
      <span class="comments__post"
            v-if=" com.auth_user.userprofile.position &&  com.auth_user.userprofile.position.name">({{
          com.auth_user.userprofile.position.name[$vuetify.lang.current] ? com.auth_user.userprofile.position.name[$vuetify.lang.current] : com.user_profile_id.position.name['eng'] ? com.user_profile_id.position.name['eng'] : com.user_profile_id.position.name['fin']
        }})</span>
    </div>
    <div v-else class="comments__name">
      unknown person
    </div>
    <div class="staff__btns comments__btns">
      <button v-if="checkFiles(com)" class="btn-icon btn-action"
              @click="downloadCommentFiles(com)">
        <picture>
          <download-icon/>
        </picture>
      </button>
      <button class="btn-icon btn-action" @click="answerStart(com.id)">
        <picture>
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
               focusable="false"
               aria-hidden="true" viewBox="0 0 24 24" data-testid="ReplyOutlinedIcon">
            <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"></path>
          </svg>
        </picture>
      </button>
      <template v-if="!hideEdit">
        <button class="btn-icon btn-action" @click="confirm_comment_open(com.id)"
                v-if="com.auth_user && (permissions.admin || (com.auth_user.id && profile === com.auth_user.id))"
        >
          <picture>
            <delete-icon/>
          </picture>
        </button>
        <button
          v-if="com.auth_user && new Date(com.create_date)>=new Date('2022-08-26') && com.auth_user.id && profile === com.auth_user.id"
          class="btn-icon btn-action" @click="edit_comment_open(com.id)">
          <picture>
            <edit-icon/>
          </picture>
        </button>
      </template>
    </div>

    <div v-if="new Date(com.create_date)<new Date('2022-08-26')" class="comments__text">
      <pre v-if="translated.indexOf(com.id) === -1" v-html="com.text"></pre>
      <pre v-else v-html="com.translated"></pre>
    </div>
    <div v-else class="comments__content">
      <div class="comments__text">
        <pre v-if="translated.indexOf(com.id) === -1" v-html="com.text"></pre>
        <!--                        <pre v-if="translated.indexOf(com.id) === -1" v-html="com.display_text"></pre>-->
        <pre v-else v-html="com.translated"></pre>
      </div>
      <div class="comments__files">
        <template v-for="(doc, index) in com.files">
          <img :key="doc"
               v-if="(['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG'].includes(doc.substr(doc.lastIndexOf('.') + 1)))"
               :src="$media_url + doc" alt="photo" @click="openLightbox(com.id, index)"/>
          <v-tooltip top v-else :key="doc">
            <template v-slot:activator="{ on, attrs }">
              <a :href="$media_url + doc"
                 v-bind="attrs"
                 v-on="on"
                 target="_blank"
              >
                <img class="document-row__photo" src="../assets/img/document-preview.svg"
                     alt="">
              </a>
            </template>
            <span>{{
                doc.substr(doc.lastIndexOf('/') + 1, doc.lastIndexOf('_') + 1) + doc.substr(doc.lastIndexOf('.'))
              }}</span>
          </v-tooltip>
        </template>
      </div>
    </div>
    <div class="comments__date">
      {{ new Date(com.create_date).toLocaleDateString('ru-Ru') }},
      {{ new Date(com.create_date).toLocaleTimeString('ru-Ru').substr(0, 5) }}
      <span v-if="translated.indexOf(com.id) === -1" class="link cp text-right"
            @click="translateCom(com)">
                        {{ $vuetify.lang.t("$vuetify.translate") }}
                      </span>
      <span v-else class="link cp text-right" @click="translated.splice(translated.indexOf(com.id), 1)">
                        {{ $vuetify.lang.t("$vuetify.showOriginal") }}
                      </span>
    </div>


    <div class="comments__list--sub comments__list">
      <CommentComponent
        v-for="subCom in subComs"
        :key="subCom.id"
        :com="subCom"
        :comments="comments"
        :table="table"
        :hide-edit="hideEdit"
        @answerStart="answerStart"
        @editOpen="edit_comment_open"
        @deleteComment="deleteComment"
        @pushCommentFile="pushCommentFileEmit"
        @openLightbox="openLightbox"
      ></CommentComponent>
    </div>

    <ConfirmModal v-model="confirm_comment_modal" @confirm="deleteComment(comment_to_delete)">
      <span v-if="alertError">{{ alertMsg }}</span>
    </ConfirmModal>
  </div>

</template>

<script>
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import ConfirmModal from "@/components/confirmModal.vue";
import {mapGetters} from "vuex";
import CommentComponent from "@/components/CommentComponent.vue";

export default {
  name: "CommentComponent",
  components: {CommentComponent, ConfirmModal, EditIcon, DownloadIcon, DeleteIcon},

  props: {
    comments: Array,
    com: Object,
    table: String,
    hideEdit: {
      type: Boolean,
      default: false,
    },
  },


  data: function () {
    return {
      alertMsg: "",
      alertError: false,
      profile: Number.parseInt(localStorage.getItem('user')),
      translated: [],
      confirm_comment_modal: false,
      comment_to_delete: null
    }
  },

  methods: {

    deleteComment(comment_to_delete) {
      this.$emit('deleteComment', comment_to_delete)
      this.confirm_comment_modal = false;

    },
    answerStart(id) {
      this.$emit('answerStart', id)
    },
    edit_comment_open(id) {
      this.$emit('editOpen', id)
    },
    confirm_comment_open(id) {
      this.confirm_comment_modal = true;
      this.comment_to_delete = id;
    },
    openLightbox(id, index) {
      this.$emit('openLightbox', id, index)
      // this.index = this.lightboxItems.findIndex(file => {
      //   return file == (this.$media_url + com.files[index])
      // })
    },
    translateCom: function () {
      let com = this.com
      this.translated.push(com.id)
      this.$axios({
        url: "https://translation.googleapis.com/language/translate/v2?key=AIzaSyCcA3--66Nt9HiqrZeamki4_ZcG1KeJOL0",
        method: "POST",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        params: {
          q: com.text,
          target: this.$vuetify.lang.current === "est" ? "et" : this.$vuetify.lang.current.substr(0, 2),
          format: "html"
        }
      })
        .then((response) => {
          com.translated = response.data.data.translations[0].translatedText
          this.$forceUpdate()
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
    parseCommentFiles(com) {
      if (com.files != null && com.files != '') {
        com.files = JSON.parse(com.files);
        for (let i = 0; i < com.files.length; i++) {
          if (['png', 'jpg', 'jpeg'].includes(com.files[i].substr(com.files[i].lastIndexOf('.') + 1).toLowerCase())) {
            this.pushCommentFileEmit(this.$media_url + com.files[i])
          }
        }
      }
    },
    checkFiles(com) {
      let flag = false
      if (com.files && com.files.length) {
        com.files.forEach(file => {
          if (['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG'].includes(file.substr(file.lastIndexOf('.') + 1))) {
            flag = true
          }
        })
      }
      return flag
    },
    async downloadCommentFiles(com) {
      this.loader = true
      for (let i = 0; i < com.files.length; i++) { // Используем цикл for, чтобы работал await
        const file = com.files[i];
        let name = file.substring(file.lastIndexOf('/') + 1, file.lastIndexOf('_')) + file.substring(file.lastIndexOf('.'));
        if (['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG'].includes(file.substring(file.lastIndexOf('.') + 1))) {
          await this.$axios({
            url: this.$hostname + 'media/' + file,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);

            fileLink.click();

            // Удаляем ссылку после клика
            document.body.removeChild(fileLink);
          }).catch((error) => {
            this.alertMsg = this.$catch(error)
            this.alertError = true
          });

          // Добавляем задержку перед загрузкой следующего файла
          await new Promise(resolve => setTimeout(resolve, 100)); // Задержка 100 мс
        }
      }
      this.loader = false
    },
    async uploadFile(file) {
      await this.$axios({
        url: this.$hostname + "time-tracking/images/upload/objects",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          file: file,
        },
      })
        .then((response) => {
          this.commentPhotoRow.push(response.data.data)
        })
        .catch((error) => {
          alert("Ошибка загрузки файла: " + error.message);
        });

      await new Promise(resolve => setTimeout(resolve, 100)); // Задержка 100 мс
    },

    pushCommentFileEmit(file) {
      this.$emit('pushCommentFile', file)

    }
  },


  computed: {
    ...mapGetters(['permissions']),
    subComs() {
      return this.comments.filter(el => el.parent_comment == this.com.id)
    }
  },
}
</script>