<template>
  <div class="sign-up">
    <div v-if="step === 1" class="sign-up__card cards__single" key="step1">
      <div class="sign-up__header">
        <div class="sign-up__title h1">
          {{ $vuetify.lang.t('$vuetify.signup.hello') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <v-text-field
          v-model="profile.name"
          :label="$vuetify.lang.t('$vuetify.signup.name')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="profile.lastname"
          :label="$vuetify.lang.t('$vuetify.signup.lastname')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="profile.phone"
          :label="$vuetify.lang.t('$vuetify.phone')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.citizenship"
          :label="$vuetify.lang.t('$vuetify.citizenship')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <div class="sign-up__buttons btn-group">
          <button
            class="btn btn-secondary"
            @click="deleteUser"
          >
            {{ $vuetify.lang.t('$vuetify.signup.cancel') }}
          </button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 2" class="sign-up__card cards__single" key="step2">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.wantTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.wantSubtitle') }}
        </div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="profile.birthdate ? new Date(profile.birthdate).toLocaleDateString('ru-Ru') : ''"
              :label="$vuetify.lang.t('$vuetify.signup.birthdate')"
              append-icon="$calendar"
              v-bind="attrs"
              v-on="on"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="profile.birthdate"
            no-title first-day-of-week="1" show-week locale-first-day-of-year="4" show-adjacent-months
            :max="new Date().toISOString()"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <div class="form__radio">
          <div class="form__radio-single custom-radio">
            <input
              class="custom-radio__hide"
              type="radio"
              name="from-finland"
              value="yes"
              id="from-finland-yes"
              v-model="options.address"
            />
            <label class="custom-radio__button" for="from-finland-yes">
              <span></span
              ></label>
            <label for="from-finland-yes">{{ $vuetify.lang.t('$vuetify.fromFinland') }}</label>
          </div>
          <div class="form__radio-single custom-radio">
            <input
              class="custom-radio__hide"
              type="radio"
              name="from-finland"
              value="no"
              id="from-finland-no"
              v-model="options.address"
            />
            <label class="custom-radio__button" for="from-finland-no"
            ><span></span
            ></label>
            <label for="from-finland-no">{{ $vuetify.lang.t('$vuetify.fromAnotherCountry') }}</label>
          </div>
        </div>
        <v-text-field
          v-model="profile.social_code_fin"
          :label="$vuetify.lang.t('$vuetify.socialCodeFin')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.tax_number"
          :label="$vuetify.lang.t('$vuetify.taxNumber')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="profile.bank_account"
          :label="$vuetify.lang.t('$vuetify.signup.bankAccount')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 3" class="sign-up__card cards__single" key="step3">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.whereTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.whereSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <div class="form__title h2" v-if="options.address === 'no'">
          {{ $vuetify.lang.t('$vuetify.signup.addressFin') }}
        </div>
        <div class="form__subtitle" v-if="options.address === 'yes'">
          {{ $vuetify.lang.t('$vuetify.signup.addressFromFin') }}
        </div>
        <div class="form__row form__address">
          <v-text-field
            class="address__street"
            v-model="profile.address_fin.street"
            :label="$vuetify.lang.t('$vuetify.street')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__house"
            v-model="profile.address_fin.house"
            :label="$vuetify.lang.t('$vuetify.house')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__entrance"
            v-model="profile.address_fin.entrance"
            :label="$vuetify.lang.t('$vuetify.entrance')"
            outlined
          ></v-text-field>
          <v-text-field
            class="address__flat"
            v-model="profile.address_fin.flat"
            :label="$vuetify.lang.t('$vuetify.flat')"
            outlined
          ></v-text-field>
        </div>
        <div class="form__row form__address">
          <v-text-field
            class="address__index"
            v-model="profile.address_fin.index"
            :label="$vuetify.lang.t('$vuetify.index')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
          <v-text-field
            class="address__city"
            v-model="profile.address_fin.city"
            :label="$vuetify.lang.t('$vuetify.city')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
        </div>
        <div v-if="options.address === 'no'">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.addressOwn') }}</div>
          <div class="form__row form__address">
            <v-text-field
              class="address__street"
              v-model="profile.address_own.street"
              :label="$vuetify.lang.t('$vuetify.street')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__house"
              v-model="profile.address_own.house"
              :label="$vuetify.lang.t('$vuetify.house')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__entrance"
              v-model="profile.address_own.entrance"
              :label="$vuetify.lang.t('$vuetify.entrance')"
              outlined
            ></v-text-field>
            <v-text-field
              class="address__flat"
              v-model="profile.address_own.flat"
              :label="$vuetify.lang.t('$vuetify.flat')"
              outlined
            ></v-text-field>
          </div>
          <div class="form__row form__address">
            <v-text-field
              class="address__index"
              v-model="profile.address_own.index"
              :label="$vuetify.lang.t('$vuetify.index')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
            <v-text-field
              class="address__city"
              v-model="profile.address_own.city"
              :label="$vuetify.lang.t('$vuetify.city')"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </div>
          <v-text-field
            class="address__country"
            v-model="profile.address_own.country"
            :label="$vuetify.lang.t('$vuetify.country')"
            outlined
            :rules="[$rules.required]"
          ></v-text-field>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 4" class="sign-up__card cards__single" key="step4">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.additionalTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.additionalSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form form__grid" @submit.prevent="">
        <div class="form__lang">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.languages') }}</div>
          <v-checkbox v-model="profile.english" label="English"></v-checkbox>
          <v-checkbox v-model="profile.estonian" label="Eesti"></v-checkbox>
          <v-checkbox v-model="profile.finnish" label="Suomi"></v-checkbox>
          <v-checkbox v-model="profile.russian" label="Русский"></v-checkbox>
          <div class="form__row">
            <v-checkbox
              v-model="other_language_check"
              :label="$vuetify.lang.t('$vuetify.other')" hide-details
            ></v-checkbox>
            <v-text-field
              hide-details
              v-model="profile.other_language"
              :disabled="!other_language_check"
            ></v-text-field>
          </div>
        </div>
        <div class="form__auto">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.additional') }}</div>
          <v-checkbox
            v-model="profile.auto"
            :label="$vuetify.lang.t('$vuetify.haveOwnCar')"
          ></v-checkbox>
          <v-checkbox
            v-model="profile.tool"
            :label="$vuetify.lang.t('$vuetify.haveOwnTools')"
          ></v-checkbox>
          <div>{{ $vuetify.lang.t('$vuetify.driverLicenseCat') }}</div>
          <div class="form__auto-category">
            <v-checkbox
              v-model="profile.auto_category"
              label="A"
              value="A"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="B"
              value="B"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="C"
              value="C"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="D"
              value="D"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="E"
              value="E"
            ></v-checkbox>
            <v-checkbox
              v-model="profile.auto_category"
              label="F"
              value="F"
            ></v-checkbox>
          </div>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 5" class="sign-up__card cards__single" key="step5">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.skillsTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.skillsSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.mainSpec') }}</div>
        <v-select
          v-model="profile.position"
          :items="positions"
          outlined attach
          item-text="name"
          item-value="id"
          :placeholder="$vuetify.lang.t('$vuetify.signup.selectSpec')"
          :menu-props="{
            bottom: true,
            offsetY: true,
            maxHeight: 200,
            contentClass: 'select-menu',
          }"
        >
          <template v-slot:selection="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
          <template v-slot:item="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
        </v-select>
        <v-autocomplete v-if="admin" v-model="currentGroup"
                        :label="$vuetify.lang.t('$vuetify.systemRole')"
                        :items="groups" item-text="name" item-value="id" return-object outlined attach
                        :menu-props="{
                  bottom: true,
                  maxHeight: 200,
                  contentClass: 'select-menu',
                }"
                        :rules="[$rules.required]">
          <template v-slot:item="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
          <template v-slot:selection="{ item }">
            {{
              item.name[$vuetify.lang.current] ? item.name[$vuetify.lang.current] : item.name['eng'] ? item.name['eng'] : item.name['fin']
            }}
          </template>
        </v-autocomplete>
        <div class="form__clothes">
          <div class="h2">{{ $vuetify.lang.t('$vuetify.sizeUniform') }}</div>
          <div class="form__grid">
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.shirt') }}</span>
              <v-text-field v-model="profile.shirt" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.pants') }}</span>
              <v-text-field v-model="profile.pants" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.jacket') }}</span>
              <v-text-field v-model="profile.jacket" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.boots') }}</span>
              <v-text-field v-model="profile.boots" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>
            </div>
            <div class="form__row">
              <span class="clothes__title">{{ $vuetify.lang.t('$vuetify.gloves') }}</span>
              <v-text-field v-model="profile.gloves" :label="$vuetify.lang.t('$vuetify.size')" outlined></v-text-field>

            </div>
          </div>
        </div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 6" class="sign-up__card cards__single" key="step9">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.almostTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.almostSubtitle') }}
        </div>
      </div>
      <v-form
        ref="form"
        class="sign-up__form sign-up__files"
        @submit.prevent=""
      >
        <div class="h2">{{ $vuetify.lang.t('$vuetify.signup.photo') }}</div>
        <div class="form__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.photoSubtitle') }}
        </div>
        <v-file-input
          v-model="files.photo.file"
          accept=".jpg, .png, .jpeg"
          :placeholder=" $vuetify.lang.t('$vuetify.downloadPhoto')"
          prepend-icon="$photo"
          :rules="[$rules.fileSize]"
        ></v-file-input>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="uploadFile(files.photo)">
            {{ $vuetify.lang.t('$vuetify.forward') }}
          </button>

        </div>
      </v-form>
    </div>

    <SpinnerLoader :overlay="loader"></SpinnerLoader>

    <div v-if="step === 7" class="sign-up__card cards__single" key="step11">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.congratTitle') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.congratSubtitle') }}
        </div>
      </div>
      <button class="sign-up__final btn btn-primary" @click="goLoginPage">
        {{ $vuetify.lang.t('$vuetify.gotoProfile') }}
      </button>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";

export default {
  name: "SignUpLayout",
  components: {
    SpinnerLoader,
  },
  props: {
    mode: String,
    id: [String, Number]
  },
  data: function () {
    return {
      url: "",
      admin: false,
      profile: {
        id: 0,
        name: "",
        lastname: "",
        citizenship: "",
        phone: "",
        phone_fin: null,
        position: null,
        birthdate: "",
        social_code_own: null,
        social_code_fin: "",
        tax_number: "",
        bank_account: "",
        address_own: {
          street: "",
          house: "",
          flat: "",
          entrance: "",
          index: "",
          city: "",
          country: "",
        },
        address_fin: {
          street: "",
          house: "",
          flat: "",
          entrance: "",
          index: "",
          city: "",
        },
        english: false,
        estonian: false,
        finnish: false,
        russian: false,
        other_language: null,
        auto: false,
        auto_category: [],
        tool: false,
        boots: '',
        jacket: '',
        pants: '',
        shirt: '',
        gloves: '',
        skills: {},
        photo_path: null,
        cards: [],
      },
      files: {
        photo: {
          path: null,
          file: null,
        },
        passport: {
          name: null,
          file: null,
        },
        residence: {
          name: null,
          file: null,
        },
      },
      currentGroup: {},
      groups: [],
      positions: [],
      valid: true,
      step: 1,
      options: {address: "yes"},
      menu1: false,
      clothes_items: {
        clothes: [42, 44, 46, 48, 50, 52, 54, 56],
        boots: [38, 39, 40, 41, 42, 43, 44, 45, 46, 47],
        gloves: [5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12]
      },
      clothes_menu: {shirt: false, pants: false, jacket: false, boots: false, gloves: false},
      other_language_check: false,
      loader: false
    };
  },
  watch: {
    profile: {
      deep: true,
      handler() {
        this.$saveForm('newProfile', this.profile)
      }
    }
  },

  created() {
    if (!(localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"))) {
      this.$router.push({name: "AuthLayout"});
    } else {
      this.$axios({
        url: this.$hostname + "time-tracking/check-profile/" + this.id,
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        if (response.data.data.data === true) {
          alert(this.$vuetify.lang.t("$vuetify.userExists"))
          this.$router.push({name: "main"});
        } else {
          this.admin = response.data.data.admin
          if (this.mode === 'worker' && this.admin === true) {
            this.url = "time-tracking/profiles"
            this.loadGroups();
          } else if (this.admin === true) {
            this.$router.push({path: `/signup/${'worker'}/${this.id}`});
          } else {
            this.url = "time-tracking/register-profile"
          }
          this.loadPositions();
          if (Object.keys(this.$loadForm('newProfile')).length !== 0)
            this.profile = this.$loadForm('newProfile')
        }
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg);
      })
    }
  },

  methods: {
    nextStep() {
      if (!this.$refs.form.validate()) {
        this.alertError = true;
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm');
      } else {
        this.step += 1;
      }
    },
    loadPositions() {
      this.$axios({
        url: this.$hostname + "time-tracking/profiles/positions",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: (localStorage.getItem("auth_token") ||
            sessionStorage.getItem("auth_token")) ?
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")) : "",
        },
      })
        .then((response) => {
          this.positions = response.data.data.data;
        })
        .catch((error) => {
          this.alertMsg = this.$catch(error)
          alert(this.alertMsg);
        });
    },
    loadGroups() {
      if (this.admin) {
        this.$axios({
          url: this.$hostname + "time-tracking/groups",
          method: "GET",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
          },
        }).then((response) => {
          this.groups = response.data.data.groups
          this.groups.forEach(group => {
            group.name = JSON.parse(group.name)
          })
        }).catch((response) => {
          this.alertMsg = this.$catch(response)
          this.alertError = true
        })
      }
    },

    addProfile() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.$axios({
          url: this.$hostname + this.url,
          method: "POST",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            auth_user_id: this.id,
            name: this.profile.name,
            lastname: this.profile.lastname,
            citizenship: this.profile.citizenship,
            birthdate: this.profile.birthdate,
            social_code_own: this.profile.social_code_own,
            social_code_fin: this.profile.social_code_fin,
            address_own: JSON.stringify(this.profile.address_own),
            address_fin: JSON.stringify(this.profile.address_fin),
            phone: this.profile.phone,
            phone_fin: this.profile.phone_fin,
            bank_account: this.profile.bank_account,
            tax_number: this.profile.tax_number,
            auto: this.profile.auto,
            auto_category: JSON.stringify(this.profile.auto_category),
            tool: this.profile.tool,
            english: this.profile.english,
            estonian: this.profile.estonian,
            finnish: this.profile.finnish,
            russian: this.profile.russian,
            other_language: this.profile.other_language,
            position: this.profile.position,
            skills: this.profile.skills,
            boots: this.profile.boots,
            jacket: this.profile.jacket,
            pants: this.profile.pants,
            shirt: this.profile.shirt,
            gloves: this.profile.gloves,
            photo_path: this.files.photo.path,
            active: true,
            cards: JSON.stringify(this.profile.cards),
          },
        })
          .then((response) => {
            this.profile.id = response.data.data.id
            this.editGroup()
            this.$clearForm('newProfile')
            this.step += 1;
            this.loader = false
          })
          .catch((error) => {
            this.loader = false
            this.alertMsg = this.$catch(error)
            alert(this.alertMsg);
            this.profile.address_own = JSON.parse(this.profile.address_own)
            this.profile.address_fin = JSON.parse(this.profile.address_fin)
            this.profile.auto_category = JSON.parse(this.profile.auto_category)
          });
      }
    },
    editGroup() {
      if (this.admin) {
        this.$axios({
          url: this.$hostname + "time-tracking/user-groups",
          method: "PUT",
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
          data: {
            auth_user_id: this.id,
            group_id: this.currentGroup.id
          },
        }).then((response) => {
          this.currentGroup.name = response.data.data.data[0]
        }).catch((response) => {
          this.alertMsg = this.$catch(response)
          this.alertError = true
        })
      }
    },
    addUserDocument(file) {
      this.$axios({
        url: this.$hostname + "time-tracking/documents/worker",
        method: "PUT",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: 0,
          user_profile_id: this.profile.id,
          name: file.name,
          path: file.path,
        },
      }).then((response) => {
        return response;
      }).catch((error) => {
        this.alertMsg = this.$catch(error)
        alert(this.alertMsg);
      })
    },
    uploadFile(file, id = null, name = null) {
      if (this.$refs.form.validate() && file.file != null) {
        this.loader = true
        this.$axios({
          url: this.$hostname + "time-tracking/images/upload/users",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            file: file.file,
          },
        })
          .then((response) => {
            file.path = response.data.data;
            file.name = name != null ? name : file.file.name;
            if (id != null) {
              this.addUserDocument(file);
            } else {
              this.addProfile();
            }
          })
          .catch((error) => {
            this.alertMsg = "Ошибка загрузки файла";
            switch (error.response.status) {
              case 400:
                this.alertMsg = error.message;
                break;
              case 403:
                this.alertMsg = "Доступ запрещен";
                break;
              case 404:
                this.alertMsg = "Страница не найдена";
                break;
            }
            alert(this.alertMsg);
            if (id == null) this.addProfile();
          })
      } else {
        this.addProfile()
      }
    },

    deleteUser() {
      this.$axios
        .delete(this.$hostname + "time-tracking/register-user", {
          data: {
            id: this.id,
          },
          headers: {
            Authorization:
              "Token " +
              (localStorage.getItem("auth_token") ||
                sessionStorage.getItem("auth_token")),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.alertMsg = "Пользователь удален";
          if (this.mode !== 'worker') {
            localStorage.clear()
            sessionStorage.clear()
            this.goLoginPage()
          } else {
            this.$router.push({name: 'workers'})
          }
        })
        .catch((response) => {
          this.alertMsg = this.$catch(response);
          this.alertError = true;
        });
    },
    goLoginPage() {
      this.$router.push({name: "AuthLayout"});
    },
  }
};
</script>

<style scoped></style>
