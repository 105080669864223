// let url = "http://" + window.location.hostname + ":8000/";
let url =  window.location.protocol + "//" + window.location.hostname + "/";
// let base_hostname = 'http://127.0.0.1:8000/'
let base_hostname = 'https://work24.fi/'
// let base_hostname = 'http://not-work24.tw1.ru/'
let media_url = url + 'media/'
// let host = window.location.hostname + ":8000";
let host = window.location.hostname;
let tech = false    

export default {
    url: url,
    base_hostname: base_hostname,
    media_url: media_url,
    host: host,
    tech: tech
}