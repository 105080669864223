<!--<template>-->
<!--  <div class="page-wrap">-->
<!--    <div class="cv-view" v-if="CV">-->
<!--      <div class="cv-view__header">-->
<!--        <div class="cv-view__text">-->
<!--          <div class="cv-view__name">{{ CV.firstname }} {{ CV.lastname }}</div>-->
<!--          <div class="cv-view__position">{{ CV.position }}</div>-->

<!--        </div>-->
<!--        <div class="cv-view__photo">-->
<!--          <img :src="$media_url + (CV.photo_path ? CV.photo_path:  'users/noavatar.png') " alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="cv-view__line-info">-->
<!--        <div class="cv-view__place">-->
<!--          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path-->
<!--                d="M8.59642 1.34307C8.98187 0.996754 9.48175 0.805176 9.99992 0.805176C10.5181 0.805176 11.018 0.996754 11.4034 1.34307L19.1034 8.26187C19.3223 8.45874 19.4974 8.69946 19.6173 8.96838C19.7372 9.23731 19.7992 9.52843 19.7992 9.82287V19.6999C19.7992 20.2568 19.578 20.791 19.1841 21.1848C18.7903 21.5786 18.2562 21.7999 17.6992 21.7999H14.1992C13.6423 21.7999 13.1081 21.5786 12.7143 21.1848C12.3205 20.791 12.0992 20.2568 12.0992 19.6999V14.7999C12.0992 14.6142 12.0255 14.4362 11.8942 14.3049C11.7629 14.1736 11.5849 14.0999 11.3992 14.0999H8.59922C8.41357 14.0999 8.23552 14.1736 8.10424 14.3049C7.97297 14.4362 7.89922 14.6142 7.89922 14.7999V19.6999C7.89922 20.2568 7.67797 20.791 7.28414 21.1848C6.89032 21.5786 6.35617 21.7999 5.79922 21.7999H2.29922C1.74226 21.7999 1.20812 21.5786 0.814295 21.1848C0.420468 20.791 0.199219 20.2568 0.199219 19.6999V9.82287C0.199219 9.22787 0.451219 8.66087 0.896419 8.26187L8.59642 1.34307ZM10.4668 2.38467C10.3384 2.26936 10.1718 2.20558 9.99922 2.20558C9.8266 2.20558 9.66008 2.26936 9.53162 2.38467L1.83162 9.30347C1.75867 9.36894 1.7003 9.44901 1.66026 9.53849C1.62023 9.62796 1.59943 9.72484 1.59922 9.82287V19.6999C1.59922 19.8855 1.67297 20.0636 1.80424 20.1948C1.93552 20.3261 2.11357 20.3999 2.29922 20.3999H5.79922C5.98487 20.3999 6.16292 20.3261 6.29419 20.1948C6.42547 20.0636 6.49922 19.8855 6.49922 19.6999V14.7999C6.49922 14.2429 6.72047 13.7088 7.1143 13.3149C7.50812 12.9211 8.04227 12.6999 8.59922 12.6999H11.3992C11.9562 12.6999 12.4903 12.9211 12.8841 13.3149C13.278 13.7088 13.4992 14.2429 13.4992 14.7999V19.6999C13.4992 19.8855 13.573 20.0636 13.7042 20.1948C13.8355 20.3261 14.0136 20.3999 14.1992 20.3999H17.6992C17.8849 20.3999 18.0629 20.3261 18.1942 20.1948C18.3255 20.0636 18.3992 19.8855 18.3992 19.6999V9.82287C18.399 9.72484 18.3782 9.62796 18.3382 9.53849C18.2981 9.44901 18.2398 9.36894 18.1668 9.30347L10.4668 2.38467Z"-->
<!--                fill="#557EAE"></path>-->
<!--          </svg>-->
<!--          <span>-->
<!--          {{ CV.residence_country }},-->
<!--          {{ CV.residence_region }},-->
<!--          {{ CV.residence_city }}-->
<!--          </span>-->
<!--        </div>-->

<!--        <a :href="'tel:'+CV.phone" class="cv-view__phone cv-view__link">-->
<!--          <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path-->
<!--                d="M5.6 17.6005C5.41435 17.6005 5.2363 17.6743 5.10503 17.8056C4.97375 17.9368 4.9 18.1149 4.9 18.3005C4.9 18.4862 4.97375 18.6642 5.10503 18.7955C5.2363 18.9268 5.41435 19.0005 5.6 19.0005H8.4C8.58565 19.0005 8.7637 18.9268 8.89497 18.7955C9.02625 18.6642 9.1 18.4862 9.1 18.3005C9.1 18.1149 9.02625 17.9368 8.89497 17.8056C8.7637 17.6743 8.58565 17.6005 8.4 17.6005H5.6Z"-->
<!--                fill="#557EAE"></path>-->
<!--            <path-->
<!--                d="M2.8 0.800537C2.05739 0.800537 1.3452 1.09554 0.820101 1.62064C0.294999 2.14574 0 2.85793 0 3.60054V20.4005C0 21.1431 0.294999 21.8553 0.820101 22.3804C1.3452 22.9055 2.05739 23.2005 2.8 23.2005H11.2C11.9426 23.2005 12.6548 22.9055 13.1799 22.3804C13.705 21.8553 14 21.1431 14 20.4005V3.60054C14 2.85793 13.705 2.14574 13.1799 1.62064C12.6548 1.09554 11.9426 0.800537 11.2 0.800537H2.8ZM1.4 3.60054C1.4 3.22923 1.5475 2.87314 1.81005 2.61059C2.0726 2.34804 2.4287 2.20054 2.8 2.20054H11.2C11.5713 2.20054 11.9274 2.34804 12.1899 2.61059C12.4525 2.87314 12.6 3.22923 12.6 3.60054V20.4005C12.6 20.7718 12.4525 21.1279 12.1899 21.3905C11.9274 21.653 11.5713 21.8005 11.2 21.8005H2.8C2.4287 21.8005 2.0726 21.653 1.81005 21.3905C1.5475 21.1279 1.4 20.7718 1.4 20.4005V3.60054Z"-->
<!--                fill="#557EAE"></path>-->
<!--          </svg>-->
<!--          {{ CV.phone }}-->
<!--        </a>-->
<!--        <a :href="'mailto:'+CV.phone" class="cv-view__email cv-view__link">-->
<!--          <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path-->
<!--                d="M19.7008 0.599854C20.629 0.599854 21.5193 0.968602 22.1757 1.62498C22.832 2.28136 23.2008 3.1716 23.2008 4.09985V15.2999C23.2008 16.2281 22.832 17.1184 22.1757 17.7747C21.5193 18.4311 20.629 18.7999 19.7008 18.7999H4.30078C3.37252 18.7999 2.48228 18.4311 1.82591 17.7747C1.16953 17.1184 0.800781 16.2281 0.800781 15.2999V4.09985C0.800781 3.1716 1.16953 2.28136 1.82591 1.62498C2.48228 0.968602 3.37252 0.599854 4.30078 0.599854H19.7008ZM21.8008 6.14525L12.3564 11.7033C12.2671 11.7557 12.1673 11.7876 12.0642 11.7968C11.961 11.806 11.8571 11.7922 11.76 11.7565L11.6452 11.7033L2.20078 6.14805V15.2999C2.20078 15.8568 2.42203 16.391 2.81586 16.7848C3.20968 17.1786 3.74383 17.3999 4.30078 17.3999H19.7008C20.2577 17.3999 20.7919 17.1786 21.1857 16.7848C21.5795 16.391 21.8008 15.8568 21.8008 15.2999V6.14525ZM19.7008 1.99985H4.30078C3.74383 1.99985 3.20968 2.2211 2.81586 2.61493C2.42203 3.00876 2.20078 3.5429 2.20078 4.09985V4.52265L12.0008 10.2879L21.8008 4.51985V4.09985C21.8008 3.5429 21.5795 3.00876 21.1857 2.61493C20.7919 2.2211 20.2577 1.99985 19.7008 1.99985Z"-->
<!--                fill="#557EAE"></path>-->
<!--          </svg>-->
<!--          {{ CV.email }}-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="cv-view__main">-->
<!--        <div class="cv-view__item cv-view__about">-->
<!--          <div class="cv-view__item-title">About</div>-->

<!--          <div class="cv-view__item-value"> text about me{{ CV.about }}</div>-->
<!--        </div>-->
<!--        <div class="cv-view__item cv-view__about">-->
<!--          <div class="cv-view__item-title">Skills</div>-->

<!--          <div class="cv-view__item-value"> {{ CV.skills }}</div>-->
<!--        </div>-->
<!--        <div class="cv-view__main-left">-->

<!--          <div class="cv-view__item">-->

<!--            <div class="cv-view__item-title">Experience</div>-->
<!--            <ul class="cv-view__list">-->
<!--              <li class="cv-view__list-item" v-for="(item, index) in CV.experience" :key="index">-->
<!--                <div class="cv-view__list-item-title"><span class="cv-view__experience-company">{{-->
<!--                    item.company_name-->
<!--                  }}</span>, {{ item.position }}-->
<!--                </div>-->
<!--                <div class="">-->
<!--                  {{ new Date(item.start_date).toLocaleDateString('ru-RU') }} - -->
<!--                  {{ new Date(item.end_date).toLocaleDateString('ru-RU') }}-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->

<!--          <div class="cv-view__item">-->
<!--            <div class="cv-view__item-title">Languages</div>-->
<!--            <ul class="cv-view__list-short">-->
<!--              <li class="cv-view__list-short-item" v-for="(item, index) in CV.languages" :key="index">-->
<!--                {{ item.language }} - {{ item.level }}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="cv-view__main-right">-->
<!--          <div class="cv-view__item">-->
<!--            <div class="cv-view__item-title">Education</div>-->
<!--            <ul class="cv-view__list">-->
<!--              <li class="cv-view__list-item" v-for="(item, index) in CV.education" :key="index">-->
<!--                <div class="cv-view__list-item-title cv-view__education-main">-->
<!--                    {{ item.institution }} - {{ $vuetify.lang.t("$vuetify.educationLevel." + item.level) }}<span v-if="item.high_level">, {{ item.high_level }}</span>-->
<!--                </div>-->
<!--                <div v-if="item.sphere" class="cv-view__education-sphere">-->
<!--                  {{ item.sphere }}, {{ item.speciality }}-->
<!--                </div>-->

<!--                <div class="cv-view__education-years">-->
<!--                  {{ item.start_year }} - {{ item.end_year }}-->
<!--                </div>-->

<!--                <div v-if="item.comment"-->
<!--                     class="cv-view__education-comment">-->
<!--                  <div class="cv-view__education-comment-title">-->
<!--                    Comment:-->
<!--                  </div>-->
<!--                  <div class="cv-view__education-comment-content">-->
<!--                    {{item.comment}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->

<!--        </div>-->
<!--        <div class="cv-view__item cv-view__certificates">-->

<!--          <div class="cv-view__item-title">Certificates</div>-->
<!--          <div class="cv-view__driver_license" v-if="CV.driver_license">-->
<!--            driving license: {{ CV.driver_license == [null] ? 'No' : CV.driver_license.join(', ')}}-->
<!--          </div>-->
<!--          <div class="cv-view__certificate-list">-->
<!--            <div class="cv-view__certificate" v-for="(item, index) in CV.certificates" :key="index">-->
<!--              <div class="cv-view__certificate-photo">-->
<!--                <img :src="$media_url + item.file" alt="">-->
<!--              </div>-->
<!--              <div class="cv-view__certificate-name">{{ item.name }}, {{ item.year }}</div>-->
<!--              <div class="cv-view__item-value">Issued: {{ item.issued }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="error&#45;&#45;text" v-if="alertError">{{ alertMsg }}</div>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div class="page-wrap">
    <div class="cv-view">
      <div class="cv-view__left">
        <div class="cv-view__left-head">
          <div class="cv-view__avatar">
            <img :src="$media_url + (CV.photo_path ? CV.photo_path:  'users/noavatar.png') " alt="">
          </div>

          <div class="cv-view__left-name">
            <div class="cv-view__name">{{ CV.firstname }}</div>
            <div class="cv-view__surname">{{ CV.lastname }}</div>
            <div class="cv-view__position">{{ CV.position }}</div>
          </div>

        </div>

        <div class="cv-view__left-block">
          <div class="cv-view__left-title">
            {{ $vuetify.lang.t("$vuetify.cvs.contacts") }}
          </div>

          <div class="cv-view__list">
            <div class="cv-view__place cv-view__ico-item">
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.59642 1.34307C8.98187 0.996754 9.48175 0.805176 9.99992 0.805176C10.5181 0.805176 11.018 0.996754 11.4034 1.34307L19.1034 8.26187C19.3223 8.45874 19.4974 8.69946 19.6173 8.96838C19.7372 9.23731 19.7992 9.52843 19.7992 9.82287V19.6999C19.7992 20.2568 19.578 20.791 19.1841 21.1848C18.7903 21.5786 18.2562 21.7999 17.6992 21.7999H14.1992C13.6423 21.7999 13.1081 21.5786 12.7143 21.1848C12.3205 20.791 12.0992 20.2568 12.0992 19.6999V14.7999C12.0992 14.6142 12.0255 14.4362 11.8942 14.3049C11.7629 14.1736 11.5849 14.0999 11.3992 14.0999H8.59922C8.41357 14.0999 8.23552 14.1736 8.10424 14.3049C7.97297 14.4362 7.89922 14.6142 7.89922 14.7999V19.6999C7.89922 20.2568 7.67797 20.791 7.28414 21.1848C6.89032 21.5786 6.35617 21.7999 5.79922 21.7999H2.29922C1.74226 21.7999 1.20812 21.5786 0.814295 21.1848C0.420468 20.791 0.199219 20.2568 0.199219 19.6999V9.82287C0.199219 9.22787 0.451219 8.66087 0.896419 8.26187L8.59642 1.34307ZM10.4668 2.38467C10.3384 2.26936 10.1718 2.20558 9.99922 2.20558C9.8266 2.20558 9.66008 2.26936 9.53162 2.38467L1.83162 9.30347C1.75867 9.36894 1.7003 9.44901 1.66026 9.53849C1.62023 9.62796 1.59943 9.72484 1.59922 9.82287V19.6999C1.59922 19.8855 1.67297 20.0636 1.80424 20.1948C1.93552 20.3261 2.11357 20.3999 2.29922 20.3999H5.79922C5.98487 20.3999 6.16292 20.3261 6.29419 20.1948C6.42547 20.0636 6.49922 19.8855 6.49922 19.6999V14.7999C6.49922 14.2429 6.72047 13.7088 7.1143 13.3149C7.50812 12.9211 8.04227 12.6999 8.59922 12.6999H11.3992C11.9562 12.6999 12.4903 12.9211 12.8841 13.3149C13.278 13.7088 13.4992 14.2429 13.4992 14.7999V19.6999C13.4992 19.8855 13.573 20.0636 13.7042 20.1948C13.8355 20.3261 14.0136 20.3999 14.1992 20.3999H17.6992C17.8849 20.3999 18.0629 20.3261 18.1942 20.1948C18.3255 20.0636 18.3992 19.8855 18.3992 19.6999V9.82287C18.399 9.72484 18.3782 9.62796 18.3382 9.53849C18.2981 9.44901 18.2398 9.36894 18.1668 9.30347L10.4668 2.38467Z"
                  fill="#557EAE"></path>
              </svg>
              <span>{{ CV.residence_address }}</span>
            </div>

            <a :href="'tel:'+CV.phone" class="cv-view__phone cv-view__link cv-view__ico-item">
              <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.6 17.6005C5.41435 17.6005 5.2363 17.6743 5.10503 17.8056C4.97375 17.9368 4.9 18.1149 4.9 18.3005C4.9 18.4862 4.97375 18.6642 5.10503 18.7955C5.2363 18.9268 5.41435 19.0005 5.6 19.0005H8.4C8.58565 19.0005 8.7637 18.9268 8.89497 18.7955C9.02625 18.6642 9.1 18.4862 9.1 18.3005C9.1 18.1149 9.02625 17.9368 8.89497 17.8056C8.7637 17.6743 8.58565 17.6005 8.4 17.6005H5.6Z"
                  fill="#557EAE"></path>
                <path
                  d="M2.8 0.800537C2.05739 0.800537 1.3452 1.09554 0.820101 1.62064C0.294999 2.14574 0 2.85793 0 3.60054V20.4005C0 21.1431 0.294999 21.8553 0.820101 22.3804C1.3452 22.9055 2.05739 23.2005 2.8 23.2005H11.2C11.9426 23.2005 12.6548 22.9055 13.1799 22.3804C13.705 21.8553 14 21.1431 14 20.4005V3.60054C14 2.85793 13.705 2.14574 13.1799 1.62064C12.6548 1.09554 11.9426 0.800537 11.2 0.800537H2.8ZM1.4 3.60054C1.4 3.22923 1.5475 2.87314 1.81005 2.61059C2.0726 2.34804 2.4287 2.20054 2.8 2.20054H11.2C11.5713 2.20054 11.9274 2.34804 12.1899 2.61059C12.4525 2.87314 12.6 3.22923 12.6 3.60054V20.4005C12.6 20.7718 12.4525 21.1279 12.1899 21.3905C11.9274 21.653 11.5713 21.8005 11.2 21.8005H2.8C2.4287 21.8005 2.0726 21.653 1.81005 21.3905C1.5475 21.1279 1.4 20.7718 1.4 20.4005V3.60054Z"
                  fill="#557EAE"></path>
              </svg>
              {{ CV.phone }}
            </a>
            <a :href="'mailto:'+CV.phone" class="cv-view__email cv-view__link cv-view__ico-item">
              <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.7008 0.599854C20.629 0.599854 21.5193 0.968602 22.1757 1.62498C22.832 2.28136 23.2008 3.1716 23.2008 4.09985V15.2999C23.2008 16.2281 22.832 17.1184 22.1757 17.7747C21.5193 18.4311 20.629 18.7999 19.7008 18.7999H4.30078C3.37252 18.7999 2.48228 18.4311 1.82591 17.7747C1.16953 17.1184 0.800781 16.2281 0.800781 15.2999V4.09985C0.800781 3.1716 1.16953 2.28136 1.82591 1.62498C2.48228 0.968602 3.37252 0.599854 4.30078 0.599854H19.7008ZM21.8008 6.14525L12.3564 11.7033C12.2671 11.7557 12.1673 11.7876 12.0642 11.7968C11.961 11.806 11.8571 11.7922 11.76 11.7565L11.6452 11.7033L2.20078 6.14805V15.2999C2.20078 15.8568 2.42203 16.391 2.81586 16.7848C3.20968 17.1786 3.74383 17.3999 4.30078 17.3999H19.7008C20.2577 17.3999 20.7919 17.1786 21.1857 16.7848C21.5795 16.391 21.8008 15.8568 21.8008 15.2999V6.14525ZM19.7008 1.99985H4.30078C3.74383 1.99985 3.20968 2.2211 2.81586 2.61493C2.42203 3.00876 2.20078 3.5429 2.20078 4.09985V4.52265L12.0008 10.2879L21.8008 4.51985V4.09985C21.8008 3.5429 21.5795 3.00876 21.1857 2.61493C20.7919 2.2211 20.2577 1.99985 19.7008 1.99985Z"
                  fill="#557EAE"></path>
              </svg>
              {{ CV.email }}
            </a>
          </div>
        </div>

        <div class="cv-view__left-block">
          <div class="cv-view__left-title">
            {{ $vuetify.lang.t("$vuetify.education") }}
          </div>
          <ul class="cv-view__list">
            <li class="cv-view__list-item cv-view__education-item" v-for="(item, index) in CV.education" :key="index">
              <div class="cv-view__list-item-title cv-view__education-main">
                {{ item.institution }} - {{ $vuetify.lang.t("$vuetify.educationLevel." + item.level) }}<span
                v-if="item.high_level">, {{ item.high_level }}</span>
              </div>
              <div v-if="item.sphere" class="cv-view__education-sphere">
                {{ item.sphere }}, {{ item.speciality }}
              </div>

              <div class="cv-view__education-years">
                {{ item.start_year }} - {{ item.end_year }}
              </div>

              <div v-if="item.comment"
                   class="cv-view__education-comment">
                <!--                <div class="cv-view__education-comment-title">-->
                <!--                  Comment:-->
                <!--                </div>-->
                <div class="cv-view__education-comment-content">
                  {{ item.comment }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="cv-view__left-block cv-view__languages">
          <div class="cv-view__left-title">{{$vuetify.lang.t("$vuetify.menu.languages")}}</div>
          <ul class="cv-view__list-short">
            <li class="cv-view__list-short-item" v-for="(item, index) in CV.languages" :key="index">
              {{ emoji[item.language] }} - {{ item.level }}
              <div class="rating__content-wrapper">
                <div class="rating__content">
                </div>
                <mark :style="'width: ' + (langStars[item.level]/6)*100+'%'"></mark>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cv-view__right">
        <div class="cv-view__right-head">
          <div class="cv-view__name">{{ CV.firstname }}</div>
          <div class="cv-view__surname">{{ CV.lastname }}</div>
          <div class="cv-view__position">{{ CV.position }}</div>
        </div>
        <div class="cv-view__right-block cv-view__about">
          <div class="cv-view__right-title">
            <span>{{ $vuetify.lang.t("$vuetify.cvs.aboutMe") }}</span></div>

          <div class=""> text about me{{ CV.about }}</div>
        </div>
        <div class="cv-view__right-block cv-view__about">
          <div class="cv-view__right-title">{{ $vuetify.lang.t("$vuetify.cvs.skills") }}</div>

          <div class=""> {{ CV.skills }}</div>
        </div>

        <div class="cv-view__right-block">
          <div class="cv-view__right-title">
            <span>
              {{ $vuetify.lang.t("$vuetify.experience") }}
            </span>
          </div>

          <ul class="cv-view__list">
            <li class="cv-view__list-item cv-view__experience-item" v-for="(item, index) in CV.experience" :key="index">

              <div class="cv-view__experience-position">
                {{ item.position }}
              </div>
              <div class="cv-view__experience-company">
                {{ item.company_name }}
              </div>
              <div class="cv-view__experience-year">
                {{ new Date(item.start_date).getFullYear() }} -
                {{ new Date(item.end_date).getFullYear() }}
              </div>
              <div class="cv-view__experience-comment">
                {{ item.comment }}
              </div>
            </li>
          </ul>

        </div>

        <div class="cv-view__item cv-view__certificates">

          <div class="cv-view__right-title">{{ $vuetify.lang.t("$vuetify.licenses") }}</div>
          <div class="cv-view__driver_license" v-if="CV.driver_license">
            driving license: {{ CV.driver_license == [null] ? 'No' : CV.driver_license.join(', ') }}
          </div>
          <div class="cv-view__certificate-list">
            <div class="cv-view__certificate"
                 v-for="(item, index) in CV.certificates" :key="index"
                 @click="openLightbox($media_url+item.file)">
              <div class="cv-view__certificate-photo">
                <img :src="$media_url + item.file" alt="">
              </div>
              <div class="cv-view__certificate-name">{{ item.name }}, {{ item.year }}</div>
              <div class="cv-view__item-value">Issued: {{ item.issued }}</div>
            </div>
          </div>
        </div>

        <div class="flex justify-end">
          <router-link class="btn btn-secondary"
                       :to="{name: 'workers-item', params: {id: CV.auth_user.userprofile.id}}">
            {{ $vuetify.lang.t('$vuetify.gotoProfile') }}
          </router-link>
          <button class="btn btn-secondary" @click="openRespond">
            {{ $vuetify.lang.t('$vuetify.cvs.invite') }}
          </button>
        </div>
      </div>
    </div>

    <CoolLightBox
      :items="lightboxItems"
      :index="lightboxItemIndex"
      @close="lightboxItemIndex = null">
    </CoolLightBox>


    <MakeOfferToWorkerModal :value="makeOfferModal" :cv_id="CV.id"></MakeOfferToWorkerModal>
  </div>
</template>

<script>
import MakeOfferToWorkerModal from "@/components/MakeOfferToWorkerModal.vue";

export default {
  name: "cv-view",
  components: {MakeOfferToWorkerModal},


  data: function () {
    return {
      CV: null,
      alertMsg: "",
      alertError: false,
      loader: false,

      lightboxItemIndex: null,
      lightboxItems: [],
      langStars: {
        "A1": 1,
        "A2": 2,
        "B1": 3,
        "B2": 4,
        "C1": 5,
        "C2": 6
      },

      emoji: {
        "az": "🇦🇿", // Azerbaijan
        "be": "🇧🇾", // Belarus
        "bg": "🇧🇬", // Bulgaria
        "cs": "🇨🇿", // Czech Republic (formerly Czechoslovakia)
        "da": "🇩🇰", // Denmark
        "de": "🇩🇪", // Germany
        "el": "🇬🇷", // Greece
        "eng": "🇬🇧", // United Kingdom
        "es": "🇪🇸", // Spain
        "est": "🇪🇪", // Estonia
        "fin": "🇫🇮", // Finland
        "fr": "🇫🇷", // France
        "he": "🇮🇱", // Israel
        "hy": "🇦🇲", // Armenia
        "it": "🇮🇹", // Italy
        "kk": "🇰🇿", // Kazakhstan
        "lt": "🇱🇹", // Lithuania
        "lv": "🇱🇻", // Latvia
        "no": "🇳🇴", // Norway
        "pl": "🇵🇱", // Poland
        "pt": "🇵🇹", // Portugal
        "ro": "🇷🇴", // Romania
        "rus": "🇷🇺", // Russia
        "sv": "🇸🇪", // Sweden
        "tg": "🇹🇯", // Tajikistan
        "tr": "🇹🇷", // Turkey
        "uk": "🇺🇦"  // Ukraine
      },

      makeOfferModal: false,
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    openLightbox(file) {
      this.lightboxItemIndex = this.lightboxItems.findIndex(lFile => lFile === file)
    },
    loadData() {
      this.$axios({
        url: this.$hostname + "time-tracking/resume/" + this.$route.params.id,
        method: "get",
        headers: {
          Authorization:
            "Token " +
            (localStorage.getItem("auth_token") ||
              sessionStorage.getItem("auth_token")),
        },
      }).then((response) => {
        this.CV = response.data.data.data
        this.parseCertificates()
      }).catch((err) => {
        this.alertMsg = this.$catch(err);
        this.alertError = true;
      }).then(() => {
        this.loader = false
      })
    },

    parseCertificates() {
      this.lightboxItems = []
      this.CV.certificates.forEach(cert => {
        this.lightboxItems.push(this.$media_url + cert.file)
      })
    },

    openRespond() {
      this.makeOfferModal = true
    },
  }
};
</script>
