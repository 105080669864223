<template>
  <div class="sign-up">
    <div v-if="step === 1" class="sign-up__card cards__single" key="step1">
      <div class="sign-up__header">
        <div class="sign-up__title h1">
          {{ $vuetify.lang.t('$vuetify.signup.wantRentTitle') }}
        </div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.signup.wantRentSubtitle') }}
        </div>
      </div>
      <v-form ref="form" class="sign-up__form" @submit.prevent="">
        <v-text-field
          v-model="tenant.name"
          :label="$vuetify.lang.t('$vuetify.companyName')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.ogrn"
          :label="$vuetify.lang.t('$vuetify.regNumber')"
          outlined
          :rules="[$rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="tenant.email"
          :label="$vuetify.lang.t('$vuetify.orgEmail')"
          outlined
          :rules="[$rules.email, $rules.notSpaces]"
        ></v-text-field>
        <v-file-input
          v-model="logo"
          accept=".jpg, .png, .jpeg"
          :placeholder=" $vuetify.lang.t('$vuetify.logo')"
          prepend-icon="$photo"
          :rules="[$rules.fileSize]"
        ></v-file-input>
        <div class="sign-up__buttons btn-group">
          <button
            class="btn btn-secondary"
            @click="goLoginPage"
          >
            {{ $vuetify.lang.t('$vuetify.signup.cancel') }}
          </button>
          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 2" class="sign-up__card cards__single" key="step2">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.profileExist') }}</div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.no') }}</button>
          <button class="btn btn-primary" @click="step=10">{{ $vuetify.lang.t('$vuetify.yes') }}</button>
        </div>
      </v-form>
    </div>
    <!--    <div v-if="step === 2" class="sign-up__card cards__single" key="step2">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.details') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.wantSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form class="sign-up__form" ref="form" @submit.prevent="">-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.bank"-->
    <!--          :label="$vuetify.lang.t('$vuetify.bank')"-->
    <!--          outlined-->
    <!--          :rules="[$rules.required]"-->
    <!--        ></v-text-field>-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.bank_account"-->
    <!--          :label="$vuetify.lang.t('$vuetify.signup.bankAccount')"-->
    <!--          outlined-->
    <!--          :rules="[$rules.required]"-->
    <!--        ></v-text-field>-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.bic"-->
    <!--          :label="$vuetify.lang.t('$vuetify.bic')"-->
    <!--          outlined-->
    <!--          :rules="[$rules.required]"-->
    <!--        ></v-text-field>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <!--    <div v-if="step === 3" class="sign-up__card cards__single" key="step3">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.whereTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.whereSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form ref="form" class="sign-up__form" @submit.prevent="">-->
    <!--        <div class="form__row form__address">-->
    <!--          <v-text-field-->
    <!--            class="address__street"-->
    <!--            v-model="tenant.address.street"-->
    <!--            :label="$vuetify.lang.t('$vuetify.street')"-->
    <!--            outlined-->
    <!--            :rules="[$rules.required]"-->
    <!--          ></v-text-field>-->
    <!--          <v-text-field-->
    <!--            class="address__house"-->
    <!--            v-model="tenant.address.house"-->
    <!--            :label="$vuetify.lang.t('$vuetify.house')"-->
    <!--            outlined-->
    <!--            :rules="[$rules.required]"-->
    <!--          ></v-text-field>-->
    <!--          <v-text-field-->
    <!--            class="address__entrance"-->
    <!--            v-model="tenant.address.entrance"-->
    <!--            :label="$vuetify.lang.t('$vuetify.entrance')"-->
    <!--            outlined-->
    <!--          ></v-text-field>-->
    <!--          <v-text-field-->
    <!--            class="address__flat"-->
    <!--            v-model="tenant.address.flat"-->
    <!--            :label="$vuetify.lang.t('$vuetify.flat')"-->
    <!--            outlined-->
    <!--          ></v-text-field>-->
    <!--        </div>-->
    <!--        <div class="form__row form__address">-->
    <!--          <v-text-field-->
    <!--            class="address__index"-->
    <!--            v-model="tenant.address.index"-->
    <!--            :label="$vuetify.lang.t('$vuetify.index')"-->
    <!--            outlined-->
    <!--            :rules="[$rules.required]"-->
    <!--          ></v-text-field>-->
    <!--          <v-text-field-->
    <!--            class="address__city"-->
    <!--            v-model="tenant.address.city"-->
    <!--            :label="$vuetify.lang.t('$vuetify.city')"-->
    <!--            outlined-->
    <!--            :rules="[$rules.required]"-->
    <!--          ></v-text-field>-->
    <!--        </div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <!--    <div v-if="step === 2" class="sign-up__card cards__single" key="step4">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.contactInfoTitle') }}</div>-->
    <!--        <div class="sign-up__subtitle">-->
    <!--          {{ $vuetify.lang.t('$vuetify.signup.contactInfoSubtitle') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <v-form class="sign-up__form" ref="form" @submit.prevent="">-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.email"-->
    <!--          label="E-mail"-->
    <!--          outlined-->
    <!--          :rules="[$rules.email, $rules.notSpaces]"-->
    <!--        ></v-text-field>-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.phone"-->
    <!--          :label="$vuetify.lang.t('$vuetify.phone')"-->
    <!--          outlined-->
    <!--        ></v-text-field>-->
    <!--        <v-text-field-->
    <!--          v-model="tenant.web"-->
    <!--          :label="$vuetify.lang.t('$vuetify.website')"-->
    <!--          outlined-->
    <!--        ></v-text-field>-->
    <!--        <div class="error&#45;&#45;text" v-if="alertError">{{ alertMsg }}</div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="nextStep">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <div v-if="step === 3" class="sign-up__card cards__single" key="step5">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.contactPerson') }}</div>
        <div class="sign-up__subtitle">
          {{ $vuetify.lang.t('$vuetify.contactPersonSubtitle') }}
        </div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-text-field
          v-model="admin.name"
          :label="$vuetify.lang.t('$vuetify.signup.name')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="admin.lastname"
          :label="$vuetify.lang.t('$vuetify.signup.lastname')"
          outlined
          :rules="[$rules.required, $rules.text]"
        ></v-text-field>
        <v-text-field
          v-model="admin.email"
          label="E-mail"
          outlined
          :rules="[$rules.required, $rules.email, $rules.notSpaces]"
        ></v-text-field>
        <v-text-field
          v-model="admin.password"
          :label="$vuetify.lang.t('$vuetify.password')"
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[$rules.required, $rules.min, $rules.notSpaces]"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
        ></v-text-field>
        <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="loadLogo">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <div v-if="step === 10" class="sign-up__card cards__single" key="step10">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.auth') }}</div>
      </div>
      <v-form class="sign-up__form" ref="form" @submit.prevent="">
        <v-text-field
          v-model="admin.email"
          label="E-mail"
          outlined
          :rules="[$rules.required, $rules.email, $rules.notSpaces]"
        ></v-text-field>
        <v-text-field
          v-model="admin.password"
          :label="$vuetify.lang.t('$vuetify.password')"
          name="password"
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[$rules.required, $rules.min, $rules.notSpaces]"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
        ></v-text-field>
        <div class="error--text" v-if="alertError">{{ alertMsg }}</div>
        <div class="sign-up__buttons btn-group">
          <button class="btn btn-secondary" @click="step = 2">{{ $vuetify.lang.t('$vuetify.back') }}</button>
          <button class="btn btn-primary" @click="setLogin">{{ $vuetify.lang.t('$vuetify.forward') }}</button>
        </div>
      </v-form>
    </div>
    <!--    <div v-if="step === 6" class="sign-up__card cards__single" key="step6">-->
    <!--      <div class="sign-up__header">-->
    <!--        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.chooseTariff') }}</div>-->
    <!--      </div>-->
    <!--      <v-form class="sign-up__form" ref="form" @submit.prevent="">-->
    <!--        <div class="cards mb-6">-->
    <!--          <div class="cards__single" v-for="plan in tariffPlans" :key="plan.id">-->
    <!--            <div class="cards__main-info">-->
    <!--              <h2 class="tariff-plan__title mb-3"> {{-->
    <!--                  plan.name[$vuetify.lang.current] ? plan.name[$vuetify.lang.current] : plan.name['eng'] ? plan.name['eng'] : plan.name['fin']-->
    <!--                }} </h2>-->
    <!--              <div class="tariff-plan__price">{{ plan.price }}€</div>-->
    <!--            </div>-->
    <!--            <div class="tariff-plan__description mb-3" v-html="plan.description"></div>-->
    <!--            <div class="mb-3">-->
    <!--              <span class="bolder">{{ $vuetify.lang.t("$vuetify.workerLimit") }}: </span>-->
    <!--              <span>{{ plan.worker_limit }}</span>-->
    <!--            </div>-->
    <!--            <div class="bolder mb-1">{{ $vuetify.lang.t("$vuetify.functions") }}:</div>-->
    <!--            <ul class="tariff-plan__functions mb-3">-->
    <!--              <template v-for="func in functions">-->
    <!--                <li v-if="plan.features.indexOf(func.id) !== -1" :key="func.id">-->
    <!--                  {{ $vuetify.lang.t("$vuetify.menu." + func.name.toLowerCase() + "") }}-->
    <!--                </li>-->
    <!--              </template>-->
    <!--            </ul>-->
    <!--            <button class="btn tariff-plan__btn"-->
    <!--                    :class="{'btn-secondary': tenant.tariff_plan==plan.id, 'btn-primary': tenant.tariff_plan!=plan.id}"-->
    <!--                    @click="tenant.tariff_plan=plan.id">-->
    <!--              <span v-if="tenant.tariff_plan!=plan.id">{{ $vuetify.lang.t('$vuetify.choose') }}</span>-->
    <!--              <span v-if="tenant.tariff_plan==plan.id">{{ $vuetify.lang.t('$vuetify.chosen') }}</span>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="error&#45;&#45;text" v-if="alertError">{{ alertMsg }}</div>-->
    <!--        <div class="sign-up__buttons btn-group">-->
    <!--          <button class="btn btn-secondary" @click="step -= 1">{{ $vuetify.lang.t('$vuetify.back') }}</button>-->
    <!--          <button class="btn btn-primary" @click="loadLogo">{{ $vuetify.lang.t('$vuetify.forward') }}</button>-->
    <!--        </div>-->
    <!--      </v-form>-->
    <!--    </div>-->
    <SpinnerLoader :overlay="loader"></SpinnerLoader>

    <div v-if="step === 4" class="sign-up__card cards__single" key="step11">
      <div class="sign-up__header">
        <div class="sign-up__title h1">{{ $vuetify.lang.t('$vuetify.signup.congratTitle') }}</div>
        <div class="sign-up__subtitle">
<!--          {{ $vuetify.lang.t('$vuetify.signup.congratRentSubtitle') }}-->
        </div>
      </div>
      <button class="sign-up__final btn btn-primary" @click="goLoginPage">
        {{ $vuetify.lang.t('$vuetify.gotoProfile') }}
      </button>
    </div>
  </div>
</template>

<script>
import SpinnerLoader from "@/components/SpinnerLoader";

let baseTenant = {
  // 'schema_name': '',
  'name': '',
  'paid_until': null,
  'created_on': new Date().toISOString().substr(0, 10),
  'on_trial': true,
  'address': {},
  'ogrn': '',
  'email': '',
  'phone': '',
  'web': '',
  'logo_path': '',
  'bank': '',
  'bank_account': '',
  'bic': '',
  'tariff_plan': '',
  'is_primary': false,
};

export default {
  name: "SignUpTenantLayout",
  components: {
    SpinnerLoader,
  },
  watch: {
    tenant: {
      deep: true,
      handler() {
        this.$saveForm('newTenant', this.tenant)
      }
    }
  },
  data: function () {
    return {
      tenant: {},
      logo: null,
      admin: {
        password: ''
      },
      tariffPlans: [],
      functions: [],

      show: false,
      step: 1,
      loader: false,
      alertError: false,
      alertMsg: ''
    };
  },
  created() {
    // this.loadTariffs()
    let newTenant = this.$loadForm('newTenant')
    if (newTenant.created_on == undefined && newTenant.name == undefined) {
      this.tenant = baseTenant
    } else {
      this.tenant = newTenant
    }
  },
  methods: {
    loadTariffs() {
      this.$axios({
        url: this.$hostname + "time-tracking/get-tariff-plans",
        method: "GET",
        params: {
          arch: true,
        },
      }).then((response) => {
        this.tariffPlans = response.data.data.data
        this.functions = response.data.data.functions
      }).catch((response) => {
        this.alertMsg = this.$catch(response)
        this.alertError = true
      })
    },
    nextStep() {
      if (!this.$refs.form.validate()) {
        this.alertError = true;
        this.alertMsg = this.$vuetify.lang.t('$vuetify.fillForm');
      } else {
        this.step += 1;
      }
    },
    loadLogo() {
      if (this.$refs.form.validate()) {
        if (this.logo) {
          this.loader = true
          this.$axios({
            url: this.$baseHostname + "time-tracking/images/upload/tenants",
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: {
              file: this.logo,
            },
          })
            .then((response) => {
              this.tenant.logo_path = response.data.data;
            })
            .catch((error) => {
              this.alertMsg = "Ошибка загрузки файла";
              switch (error.response.status) {
                case 400:
                  this.alertMsg = error.message;
                  break;
                case 403:
                  this.alertMsg = "Доступ запрещен";
                  break;
                case 404:
                  this.alertMsg = "Страница не найдена";
                  break;
              }
              alert(this.alertMsg);
            }).finally(() => {
            this.addTenant()
          })
        } else {
          this.addTenant()
        }
      }
    },
    addTenant() {
      if (this.$refs.form.validate()) {
        this.loader = true
        this.tenant.address = JSON.stringify(this.tenant.address)
        this.tenant.email = this.tenant.email.toLowerCase().replace(" ", "")
        this.$axios({
          url: this.$baseHostname + 'time-tracking/organization',
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            data: JSON.stringify(this.tenant),
            user: JSON.stringify({
              email: this.admin.email.toLowerCase().replace(" ", ""),
              password: this.admin.password,
            }),
            profile: JSON.stringify(({
              name: this.admin.name ?? '',
              lastname: this.admin.lastname ?? '',
            }))
          }
        })
          .then(() => {
            this.$clearForm('newTenant')
            this.step = 4;
            this.loader = false
          })
          .catch((error) => {
            this.alertMsg = this.$catch(error)
            alert(this.alertMsg + ' Contact with admin');
            this.tenant.address = JSON.parse(this.tenant.address)
            this.loader = false
          });
      }
    },
    setLogin() {
      this.loader = true
      if (this.$refs.form.validate()) {
        this.$axios({
          url: this.$hostname + "auth/token/login",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: {
            email: this.admin.email.toLowerCase().replace(" ", ""),
            password: this.admin.password,
          },
        }).then((response) => {
          if (response.data.data.attributes.auth_token){
            this.loadLogo()
          }
        }).catch((error) => {
          this.loader = false
          this.alertMsg = this.$catch(error)
          this.alertError = true;
        });
      }
    },
    goLoginPage() {
      this.$router.push({name: "AuthLayout"});
    },
  }
}
</script>

<style scoped>

</style>