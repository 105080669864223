
<template>
  <div class="accordion" :class="{'close' : closed_d, 'cards__single' : card}" ref="">
    <div class="accordion__title" @click="switchAccordion">
      <div class="accordion-btn btn-icon btn-action">
        <svg width="19" height="10" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 0.5L4.5 4.5L8 0.5" stroke="#2C1521" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </svg>
      </div>

      <div class="accordion__title-text h2 flex">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="accordion__content ">

      <div class="accordion__text">
        <slot name="content"></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AccordionComponent",


  data: function () {
    return {
      closed_d: this.closed
    }
  },

  props: {
    closed: Boolean,
    card: Boolean
  },

  mounted(){
    this.closed_d = this.closed

    setTimeout(()=>{
    if (!this.closed_d){
        const accordionText = this.$el.querySelector('.accordion__text');
        const content = this.$el.querySelector('.accordion__content');
        if (content) {
          content.style.height = `${accordionText.offsetHeight}px`;
        }
    }
    }, 300)

  },

  watch: {
    closed(){
      console.log('watch closed')
      if(this.closed_d != this.closed){
        this.switchAccordion()
      }

    }
  },

  methods: {

    switchAccordion() {
      // const target = event.target;
      this.closed_d = !this.closed_d
      const card = this.$el
      const height = card.getElementsByClassName('accordion__text')[0].offsetHeight

      const content = card.getElementsByClassName('accordion__content')[0]

      if (card.classList.contains('close')) {
        content.style.height = height + 'px'

        card.classList.remove('close');
        content.classList.remove('accordion__content--close');
        this.updateSize(card, height)
      } else {
        content.style.height = 0 + 'px'

        card.classList.add('close');
        content.classList.add('accordion__content--close');

        let parent = this.findInParentsByClassName(card, 'accordion')
        if (parent) {
          this.updateSize(parent, -height)
        }

      }
    },
    updateSize(card, dif) {
      const content = card.getElementsByClassName('accordion__content')[0]
      const height = card.getElementsByClassName('accordion__content')[0].clientHeight
      //
      // console.log(content.clientHeight)
      // console.log(content.offsetHeight)
      // console.log(height)
      // console.log(dif)
      content.style.height = height+dif + 'px'


      let parent = this.findInParentsByClassName(card, 'accordion')
      if (parent) {
        this.updateSize(parent, dif)
      }
    },
    findInParentsByClassName(element, className) {
      while (element && element !== document.body) {
        element = element.parentElement;
        if (element.classList.contains(className)) {
          return element;
        }
      }
      return null;
    }

  }
}
</script>
