<template>
  <v-dialog v-model="confirm_modal">
    <div class="modal modal-small">
      <div class="h1" v-if="active === 'working'">{{ $vuetify.lang.t('$vuetify.sureArchive') }}</div>
      <div class="h1" v-if="active === 'archived'">{{ $vuetify.lang.t('$vuetify.sureOutArchive') }}</div>
      <div class="error--text">
        <slot></slot>
      </div>
      <div class="sign-up__buttons btn-group">
        <button class="btn btn-secondary text-capitalize" @click="confirm_modal = false">
          {{ $vuetify.lang.t('$vuetify.no') }}
        </button>
        <button class="btn btn-primary text-capitalize" @click="$emit('confirm', true)">
          {{ $vuetify.lang.t('$vuetify.yes') }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmArchiveModal",
  props: {
    value: {
      type: Boolean,
    },
    active: Boolean,
  },
  data: function () {
    return {
      confirm_modal: this.value,
    };
  },

  watch: {
    value() {
      this.confirm_modal = this.value;
    },
    confirm_modal() {
      this.$emit("input", this.confirm_modal);
    },
  },
}
</script>

<style scoped>

</style>