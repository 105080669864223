<template>
  <div class="vacancies-list-wrap">

    <div class="vacancies-list">
      <div class="vacancy-item" v-for="vacancy in vacancies" :key="vacancy.id">
        <a :href="'/vacancies/'+vacancy.id" class="vacancy-item__name">{{ vacancy.name }}</a>
        <div class="vacancy-item__sub-line">
          <div class="vacancy-item__salary">{{ vacancy.salary }}</div>
          <div v-if="vacancy.remote" class="vacancy-item__remote">{{ $vuetify.lang.t('$vuetify.hh.remote') }}</div>
        </div>
        <div class="vacancy-item__company">
          ООО компания
        </div>
        <div class="vacancy-item__address">
          {{ vacancy.address }}
        </div>

        <div class="vacancy-item__buttons">
          <button v-if="!showEdit" class="btn btn-primary" @click="openRespond(vacancy)">{{ $vuetify.lang.t('$vuetify.hh.respond') }}</button>
          <template v-if="showEdit">
            <button v-if="permissions.edit.indexOf('HR') !== -1 && vacancy.organization.id == company"
                    class="btn btn-primary" @click="openEdit(vacancy)">{{ $vuetify.lang.t('$vuetify.edit') }}
            </button>
            <button v-if="permissions.edit.indexOf('HR') !== -1 && vacancy.organization.id == company"
                    class="btn btn-secondary" @click="confirmDelete(vacancy)">{{ $vuetify.lang.t('$vuetify.delete') }}
            </button>
          </template>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "vacancy-list",

  props: {
    vacancies: Array,
    showEdit: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      company: JSON.parse(localStorage.getItem('organization')).id
    }
  },

  methods: {
    openEdit(vacancy) {
      this.$emit('openEdit', vacancy)
    },

    confirmDelete(vacancy) {
      this.$emit('openDelete', vacancy)
    },

    openRespond(vacancy) {
      this.$emit('openRespond', vacancy)
    }
  },

  computed: {
    ...mapGetters(['permissions']),
  }
}
</script>
